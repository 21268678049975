"use client";

import { useEnabledCookies } from "@/lib/cookies/useEnabledCookies";
import { Analytics as VercelAnalytics } from "@vercel/analytics/react";
import Script from "next/script";

export function Trackers() {
	const enabledCookies = useEnabledCookies();

	const performanceEnabled = !!enabledCookies?.has("performance");
	return (
		<>
			<VercelAnalytics />
			<GoogleAnalytics enabled={performanceEnabled} />
		</>
	);
}
const GoogleAnalytics = ({ enabled }: { enabled: boolean }) => {
	if (!enabled) return null;
	return (
		<>
			<Script strategy="afterInteractive" src={"https://www.googletagmanager.com/gtag/js?id=G-6V5SE3MNX4"} />
			<Script
				id="gtag-init"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-6V5SE3MNX4', {
            page_path: window.location.pathname,
            cookie_flags: 'max-age=7200;secure;samesite=none'
          });
        `,
				}}
			/>
		</>
	);
};
